import date_format from 'date-fns/format'
import date_parse from 'date-fns/parse'
import date_locale_fr from 'date-fns/locale/fr'
import date_parseISO from 'date-fns/parseISO'
import { NextPageContext } from 'next'
import Router from 'next/router'

export type FetchError = { status: number; error_json: any }

export const fetcher = async <T>(...args: Parameters<typeof fetch>): Promise<T> => {
  const res_fetch = await fetch(...args)

  if (res_fetch.ok) return res_fetch.json()

  const error_json = await res_fetch.json()
  // eslint-disable-next-line no-console
  console.error('fetcher', res_fetch.status, res_fetch.statusText, error_json)

  throw { status: res_fetch.status, error_json } as FetchError
}

export const fccur = (v: number, precision = 2) => {
  const prec = Math.pow(10, precision - 2)
  const vrect = Math.round(prec * v) / (prec * Math.pow(10, 2))

  const enUs = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: precision,
  }).format(vrect)

  return `${enUs.replace(/,/g, ' ')} €`
}

export const ficur = (v: number) => fccur(Math.round(v * 100), 0)

export const options_from_hashmap = <T extends string>(
  hashmap: Record<T, string>
): { value: T; label: string }[] =>
  Object.keys(hashmap).map((key: any) => ({ value: key, label: hashmap[key as T] }))

export const date_ntoh = (date_native: Date, format = 'dd/MM/yyyy') => {
  return date_format(date_native, format, { locale: date_locale_fr })
}

export const date_btoh = (date_iso: string, format = 'dd/MM/yyyy') => {
  const parsedDate = date_parseISO(date_iso)

  return isNaN(parsedDate as any) ? null : date_ntoh(parsedDate, format)
}

export const date_htob = (date_h: string, format = 'dd/MM/yyyy') => {
  try {
    const parsed = date_parse(date_h, format, new Date(), { locale: date_locale_fr })
    return date_format(parsed, 'yyyy-MM-dd')
  } catch (err) {
    return null
  }
}

type NextRedirectOptions = {
  to: string
  ctx: NextPageContext
}

export const nextRedirect = async (options: NextRedirectOptions): Promise<any> => {
  if (typeof window === 'undefined') {
    options.ctx.res?.writeHead(307, { Location: options.to })
    options.ctx.res?.end()
  } else {
    Router.replace(options.to)
  }

  return new Promise(() => {
    /* do nothing */
  })
}
