import { FetchError, fetcher } from '@/component/misc'
import { Api_Me_Response } from '@/pages/api/auth/me'
import { Box } from '@/styles/system'
import styled from '@emotion/styled'
import Cookies from 'js-cookie'
import { signOut } from 'next-auth/react'
import Link from 'next/link'
import Select from 'react-select'
import useSWR from 'swr'
import { Api_Settings_Response } from '../pages/api/settings'

const Header = styled(Box)`
  grid-area: header;
  height: 90px;
  border-bottom: 1px solid var(--color-thin);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  text-transform: uppercase;

  .source-selector {
    input {
      height: auto;
    }
  }
`

const SourceSelector = () => {
  const swr_settings = useSWR<Api_Settings_Response, FetchError>(`/api/settings`, fetcher)
  const sources = Object.keys(swr_settings.data?.contact_default || {})

  if (sources.length <= 1) return null

  const selected = Cookies.get('selected_source')

  type Option = { value: string; label: string }
  const options: Option[] = [
    { value: '', label: 'Toutes les agences' },
    ...sources.map((source) => ({
      value: source,
      label: swr_settings.data!.contact_default[source].name,
    })),
  ]

  const onChange = (option: Option | null) => {
    Cookies.set('selected_source', option?.value || '') // no expiration = session cookie
    location.href = location.href
  }

  return (
    <Box mr={20} className="source-selector">
      <Select
        options={options}
        isSearchable
        defaultValue={selected ? options.find((o) => o.value === selected) : null}
        onChange={onChange}
        placeholder="Changer d'agence"
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: 'var(--color-light)',
            primary25: '#EEE',
            primary50: '#EEE',
          },
        })}
        styles={{
          control: (old) => {
            return {
              ...old,
              border: '1px solid var(--color-thin) !important',
              boxShadow: 'none',
            }
          },
          container: (old) => ({ ...old, minWidth: '190px' }),
          input: (old) => ({ ...old, height: '36px' }),
          indicatorSeparator: () => ({ display: 'none' }),
          valueContainer: (old) => ({ ...old, padding: '0 0 0 16px' }),
          placeholder: (old) => ({ ...old, color: 'var(--color-dark)' }),
        }}
      />
    </Box>
  )
}

export const ProtectedHeader = ({ me }: { me: Api_Me_Response }) => {
  return (
    <Header as="header" className="header">
      <Link href="/annonce">
        <img
          src="https://res.cloudinary.com/loueragile/image/upload/v1683534176/JinkaPro/Logo-JinkaPro.svg"
          alt="Logo"
        />
      </Link>
      <Box display="flex" alignItems="center">
        {me.acl_type === 'source' ? <SourceSelector /> : null}
        <Link href={me.acl_type !== 'source' ? '/' : '/account'} style={{ color: 'inherit' }}>
          <Box
            px={20}
            py={10}
            border="1px solid"
            borderColor="thin"
            borderRadius="5px"
            display="flex"
            alignItems="center"
            style={{}}
          >
            {me.logo ? (
              <img
                style={{
                  width: '20px',
                  height: '20px',
                }}
                src={me.logo}
                onError={(e) => {
                  e.currentTarget.src = `https://ui-avatars.com/api/?name=${me.name}&background=0D8ABC&color=fff&rounded=true`
                }}
                alt="logo"
              />
            ) : null}
            <Box as="span" ml={10}>
              {me.name}
            </Box>
          </Box>
        </Link>
        <Box
          ml={20}
          color="tertiary"
          style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
          onClick={() => signOut()}
        >
          Déconnexion
        </Box>
      </Box>
    </Header>
  )
}
